<template>
    <div style="width:100%">
        <div class="of-form-row">
            <el-form-item label="Instrument" prop="isin">
                <el-select 
                    v-model="formdata.isin"
                    @change="load_isin"
                    disabled>
                    <el-option
                        v-for="item in isin_list"
                        :key="item.isin"
                        :label="item.isin"
                        :value="item.isin"
                        v-if="item.q">
                        <div class="flex_c" style="height: 100%;">
                            <div class="of-search-isin-isin">{{ item.isin }}</div>
                            <div class="of-search-isin-price" style="font-size:11px;">
                                <FormattedNumber
                                    :value="item.q" :decimals="2" color />
                            </div>
                        </div>
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Trade Currency" prop="tcur">
                <el-select 
                    v-model="formdata.tcur">
                    <el-option
                        v-for="item in currency_list"
                        :key="item.code"
                        :label="item.code"
                        :value="item.code">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="Coupon Amount" prop="tpr">
                <el-input
                    v-model="formdata.tpr"
                    @blur="blur_format_field('tpr')"
                    :disabled="!this.isin_data.isin">
                    <template slot="prepend">{{formdata.tcur}}</template> 
                </el-input>
            </el-form-item>

            <el-form-item label="Cut-Off Date" prop="cutoff_dt">
                <el-date-picker
                    v-model="formdata.cutoff_dt"
                    type="date"
                    :clearable="false"
                    format="dd MMM yyyy"
                    :picker-options="{disabledDate: disabled_cutoff_date}">
                </el-date-picker>
            </el-form-item>
        </div>

        <div class="of-form-row" v-if="show_conversion_rate">
            <el-form-item label="Portfolio Conversion Rate" prop="tcur_prate">
                <el-input
                    v-model="formdata.tcur_prate"
                    @blur="blur_format_field('tcur_prate')">
                    <template slot="prepend">{{formdata.tcur}}{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
            <el-form-item label="Portfolio Coupon Amount" prop="tcur_prate_amount">
                <el-input
                    v-model="formdata.tcur_prate_amount"
                    @blur="blur_format_field('tcur_prate_amount')"
                    @change="change_tcur_prate_amount">
                    <template slot="prepend">{{portfolio_cur}}</template>    
                </el-input>
            </el-form-item>
        </div>
    </div>
</template>

<script>
import common from './operation_mixin'
import FormattedNumber from '@/components/Formats/FormattedNumber'

export default {
    mixins: [common],
    components: { FormattedNumber },

    props: {
        asset: {
            type: String,
            required: true
        },
        client_id: {
            type: String,
            required: true
        },
        portfolio_date: {
            required: true
        }
    },

    data(){
        return {
            isin_list: []
        }
    },

    methods: {
        load_isin_list(){
            this.clear_data()
            this.isin_list = []
        },

        load_isin(isin){
            this.$set(this.formdata, 'isin', isin);
            this.$store.dispatch('instrument/getInstrumentShort', { asset: this.asset, isin: isin, dt:this.trade_date }).then((response) => {
                this.change_isin(response)
            })
        },

        change_isin(item){
            this.clear_data()

            this.$set(this.formdata, 'isin', item.isin);
            this.$set(this.formdata, 'q', 1);
            this.isin_data = { isin : this.formdata.isin };
            this.set_isin_data(item)

            this.$set(this.formdata, 'tcur', item.cur)
            this.$set(this.formdata, 'st_cur', item.cur)
            this.$set(this.formdata, 'st_cur_rate', 1)
        },

        count_amounts(){
            let q = this.get_number_field('q')
            let tpr = this.get_number_field('tpr')
            let tcur_prate = this.get_number_field('tcur_prate')
            
            this.$set(this.formdata, 'tcur_prate_amount', q * tpr * tcur_prate)
        },

        disabled_cutoff_date(date){
            let is_disabled = true
            if (date >= this.$moment(this.portfolio_date).toDate() && date <= new Date())
                is_disabled = false
            
            return is_disabled
        }
    },

    watch: {
        asset(){
            this.load_isin_list()
        },

        "formdata.tpr": function(val, old_val) {
            if (old_val === val) return;

            this.count_amounts()
        },

        "formdata.q": function(val, old_val) {
            if (old_val === val) return;

            this.$set(this.formdata, 'q', 1);
        },

        "formdata.tcur_prate": function(val, old_val) {
            if (old_val === val) return;
            
            this.count_amounts()
        },

        "formdata.cutoff_dt": function(val, old_val) {
            this.$emit('field-change', { field: 'cutoff_dt', value:this.formdata.cutoff_dt })
        },
    },

    mounted(){
        this.load_isin_list()
    }
}
</script>
